// import * as app from "firebase/app";
// import "firebase/analytics";

// const firebaseConfig = {
//   apiKey: "AIzaSyB3eivqLfS71D0QA38SVPKY9GkF2rL1Cb8",
//   authDomain: "superjack-web.firebaseapp.com",
//   projectId: "superjack-web",
//   storageBucket: "superjack-web.appspot.com",
//   messagingSenderId: "138607389911",
//   appId: "1:138607389911:web:d3e30af3be739e7a4c9492",
//   measurementId: "G-DVRFDN9GQ3",
// };

// app.initializeApp(firebaseConfig);

// export const track = (event) => {
//   app.analytics().logEvent(event.eventName, event.eventParameters);
// };

// export default track;



// import { initializeApp } from "firebase/app";
// import { getAnalytics, logEvent, isSupported } from "firebase/analytics";

// const firebaseConfig = {
//   apiKey: "AIzaSyB3eivqLfS71D0QA38SVPKY9GkF2rL1Cb8",
//   authDomain: "superjack-web.firebaseapp.com",
//   projectId: "superjack-web",
//   storageBucket: "superjack-web.appspot.com",
//   messagingSenderId: "138607389911",
//   appId: "1:138607389911:web:d3e30af3be739e7a4c9492",
//   measurementId: "G-DVRFDN9GQ3",
// };

// // Initialize Firebase
// let app = null;
// let analytics = null;

// isSupported().then((result) => {
//     if (result) {
//         app = initializeApp(firebaseConfig);
//         analytics = getAnalytics(app);
//     }
// })

// // // simple event
// // logEvent(analytics, 'your_event_name');

// // // firebase defined events like page_view etc.
// // logEvent(analytics, 'page_view', `your_event_parameter`)


// export const track = (event) => {
//   // console.log(
//   //   `TRACKING ${event.eventName} with ${JSON.stringify(event.eventParameters)}`
//   // );
//   logEvent(analytics, event.eventName, event.eventParameters);
// };

// export default track;


import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyB3eivqLfS71D0QA38SVPKY9GkF2rL1Cb8",
  authDomain: "superjack-web.firebaseapp.com",
  projectId: "superjack-web",
  storageBucket: "superjack-web.appspot.com",
  messagingSenderId: "138607389911",
  appId: "1:138607389911:web:d3e30af3be739e7a4c9492",
  measurementId: "G-DVRFDN9GQ3",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const track = (event) => {
  logEvent(analytics, event.eventName, event.eventParameters);
};

export default track;